import * as React from "react";
import { createContext, PropsWithChildren } from "react";

interface ContextValue {
  initialized: boolean;
  setInitialized: (initialized: boolean) => void;
}

export const ParticlesContext = createContext<ContextValue>({
  initialized: false,
  setInitialized: () => {},
});

export const ParticlesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [initialized, setInitialized] = React.useState(false);

  return (
    <ParticlesContext.Provider value={{ initialized, setInitialized }}>
      {children}
    </ParticlesContext.Provider>
  );
};
