import * as React from "react";
import { useIsClient, useMediaQuery } from "@uidotdev/usehooks";
import { ISourceOptions } from "@tsparticles/engine";
import { LazyParticles } from "./LazyParticles";
import particleImage from "./particle.png";

interface Props {
  className?: string;
}

export const DustParticles: React.FC<Props> = (props) => {
  return (
    <React.Suspense fallback={null}>
      <LazyParticles options={options} {...props} />
    </React.Suspense>
  );
};

const ClientDesktopDustParticles: React.FC<Props> = (props) => {
  const desktop = useMediaQuery("(min-width: 1024px)");
  return desktop ? <DustParticles {...props} /> : null;
};
export const DesktopDustParticles: React.FC<Props> = (props) => {
  const client = useIsClient();
  return client ? <ClientDesktopDustParticles {...props} /> : null;
};

const options: ISourceOptions = {
  fpsLimit: 120,
  particles: {
    groups: {
      tiny: {
        shape: {
          type: "image",
          options: {
            image: {
              src: particleImage,
            },
          },
        },
        size: {
          value: { min: 3, max: 8 },
        },
        number: {
          value: 50,
        },
      },
    },
    color: {
      value: "#ffffff",
    },
    shape: {
      type: "circle",
    },
    size: {
      value: 1,
    },
    move: {
      enable: true,
      straight: false,
      direction: "none",
      speed: { min: 1, max: 3 },
      size: true,
      outModes: {
        default: "out",
      },
    },
    number: {
      value: 400,
    },
    opacity: {
      value: { min: 0, max: 0.3 },
      animation: {
        enable: true,
        sync: false,
        speed: 0.5,
      },
    },
    wobble: {
      enable: true,
      distance: 10,
      speed: 10,
    },
  },
  detectRetina: true,
};
