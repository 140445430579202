import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { config } from "@fortawesome/fontawesome-svg-core";
import { ParticlesProvider } from "./src/components/Particles";
import "./src/styles/global.css";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
    >
      <ParticlesProvider>{element}</ParticlesProvider>
    </GoogleReCaptchaProvider>
  );
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  config.autoAddCss = false;
};
